import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import store from "../store";
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload,
  };
};

const mintRequest = () => {
  return {
    type: "MINT_REQUEST",
  };
};

const mintSuccess = () => {
  return {
    type: "MINT_SUCCESS",
  };
};

const mintFailed = () => {
  return {
    type: "MINT_FAILED",
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const ENV = process.env;
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == ENV.REACT_APP_NETWORK_ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            ENV.REACT_APP_CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${ENV.REACT_APP_NETWORK_NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const mint = () => {
  return async (dispatch) => {
    dispatch(mintRequest());
    const cost = process.env.REACT_APP_WEI_COST;
    const gasPrice = process.env.REACT_APP_GAS_PRICE;
    const gasLimit = process.env.REACT_APP_GAS_LIMIT;

    const blockchain = await store.getState().blockchain;

    const tx = {
      gasPrice: String(gasPrice),
      gas: String(gasLimit),
      to: process.env.REACT_APP_CONTRACT_ADDRESS,
      from: blockchain.account,
      value: cost
    };

    blockchain.smartContract.methods
      .discoverEnt('ENT')
      .send(tx)
      .once("error", (err) => {
        dispatch(mintFailed());
      })
      .then((receipt) => {
        dispatch(mintSuccess());
        dispatch(fetchData(blockchain.account));
      });
  }
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};