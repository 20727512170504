import {
  MINTING_ERROR,
  MINTING_TOKEN,
  MINT_MESSAGE,
  SUCCESSFULLY_MINTED
} from "../../constants/errors";

const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  mintMessage: MINT_MESSAGE,
  minting: false,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "MINT_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        mintMessage: MINTING_TOKEN.replace('$i', process.env.REACT_APP_NFT_NAME),
        minting: true,
        errorMsg: "",
      };
    case "MINT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        mintMessage: SUCCESSFULLY_MINTED.replace('$i', process.env.REACT_APP_NFT_NAME),
        minting: false,
        errorMsg: "",
      };
    case "MINT_FAILED":
      return {
        ...state,
        loading: false,
        error: true,
        mintMessage: MINTING_ERROR,
        minting: false,
        errorMsg: MINTING_ERROR,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
