import { Network, Alchemy } from "alchemy-sdk";
import store from "../store";
import { GENERIC_ERROR } from '../../constants/errors';

const initAlchemy = (payload) => {
  return {
    type: "INIT_ALCHEMY",
    payload
  };
};

const getTokensRequest = () => {
  return {
    type: "GET_TOKENS_REQUEST",
  };
};

const getTokensSuccess = (payload) => {
  return {
    type: "GET_TOKENS_SUCCESS",
    payload
  };
};

const getTokensFailed = (payload) => {
  return {
    type: "GET_TOKENS_FAILED",
    payload
  };
};

const resetAll = () => {
  return { type: "RESET" };
};

const getCollectionTokensAlchemy = async (alchemy) => {
  const address = process.env.REACT_APP_CONTRACT_ADDRESS;
  if (alchemy) {
    const tokens = await alchemy.nft.getNftsForContract(address);
    return tokens.nfts;
  }
  return [];
};

export const initializeAlchemy = () => {
  return async (dispatch) => {
    const ENV = process.env;
    const settings = {
      apiKey: ENV.REACT_APP_ALCHEMY_KEY,
      network: ENV.REACT_APP_IS_MAINNET ? Network.MATIC_MAINNET : Network.MATIC_MUMBAI,
    };
    const alchemyInstance = new Alchemy(settings);
    dispatch(initAlchemy({ alchemyInstance }));
  }
};

export const getTokensAlchemy = () => {
  return async (dispatch) => {
      dispatch(getTokensRequest());
      try {
        const alchemy = await store.getState().alchemy.alchemyInstance;
        const tokens = await getCollectionTokensAlchemy(alchemy);
        dispatch(getTokensSuccess({ tokens }));
      } catch (err) {
        dispatch(getTokensFailed(GENERIC_ERROR));
      }
  }
}

export const reset = () => {
  return async (dispatch) => {
      dispatch(resetAll());
  }
}
