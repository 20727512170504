import { Link } from "react-router-dom";
import styled from "styled-components";
import * as s from "../../styles/globalStyles";

export const HeaderLogo = styled.img`
  height: 80px;
  margin: 0 auto;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const HeaderLogoWrapper = styled.div`
  text-align: center;
`;

export const TextHeader = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
  font-weight: bold;
  margin-left: 16px;
  cursor: pointer;
  z-index: 1000;
`;

function Header() {
    return (
        <s.Container
            fd={"row"}
            jc={"flex-end"}
            ai={"center"}
            style={{
                position: "relative",
                padding: 24,
                paddingBottom: 0,
                zIndex: 1000
            }}
        >   <s.Container flex={"1 0 33.333%"} />
            <HeaderLogoWrapper><HeaderLogo alt={"logo"} src={"/config/images/logo.png"} /></HeaderLogoWrapper>
            <s.Container flex={"1 0 33.333%"} fd={"row"} jc={"flex-end"}>
            </s.Container>
        </s.Container>
    );
}

export default Header;
