import * as s from "../../styles/globalStyles";

function TitleDescription(props) {
    const { title, description } = props;

    return (
        <s.Container flex={1} ai={"flex-start"} style={{ padding: "24px 24px 24px 60px" }}>
          <s.MainTitle style={{ color: "var(--primary-text)" }}>
            { title }
          </s.MainTitle>
          <s.Container fd={"row"} flex={1}>
            <s.TextDescription
              style={{
                textAlign: "justify",
                color: "var(--primary-text)",
              }}
            >
              { description }
            </s.TextDescription>
            <s.SpacerCustom w={"100%"} />
          </s.Container>
        </s.Container>
    )
}

export default TitleDescription;