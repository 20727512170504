import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import { truncate } from "../utils/utils";
import TitleDescription from "../components/common/TitleDescription";
import { connect, mint } from "../redux/blockchain/blockchainActions";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  border: 2px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  display: none;
  @media (min-width: 767px) {
    width: 150px;
    display: block;
  }
  @media (min-width: 1000px) {
    width: 200px;
    display: block;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function Buy() {
    const dispatch = useDispatch();
    const alchemy = useSelector((state) => state.alchemy);
    const blockchain = useSelector((state) => state.blockchain);
    const ENV = process.env;

    return (
        <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 48 }} test>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            fd={"row"}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
            <s.Container flex={2} jc={"center"} ai={"center"}>
              <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 28,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
              >
                GENERAL INFO
              </s.TextTitle>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                NFT Name:
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                { process.env.REACT_APP_NFT_NAME }
              </s.TextDescription>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                NFT Symbol:
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                { process.env.REACT_APP_SYMBOL }
              </s.TextDescription>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                Total sold:
              </s.TextTitle>
              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                { alchemy.tokens.length } / { ENV.REACT_APP_MAX_SUPPLY }
              </s.TextTitle>
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent-text)" }}
              >
                Contract address:
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
                <StyledLink target={"_blank"} href={ENV.REACT_APP_SCAN_LINK}>
                  {truncate(ENV.REACT_APP_CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.TextDescription>
              <s.SpacerSmall />
              <s.SpacerMedium />
            </s.Container>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              fd={"column"}
              style={{
                backgroundColor: "var(--accent)",
                padding: 24,
                borderRadius: 24,
                border: "4px solid var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              }}
            >
              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                SALE IS ACTIVE!
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
                { alchemy.tokens.length >= ENV.REACT_APP_MAX_SUPPLY ? (
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      You can still find { ENV.REACT_APP_NFT_NAME } on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} /*href={CONFIG.MARKETPLACE_LINK} */>
                      { ENV.REACT_APP_MARKETPLACE }
                    </StyledLink>
                  </>
                ) : (
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      1 { ENV.REACT_APP_SYMBOL } costs { ENV.REACT_APP_DISPLAY_COST }{" "}
                      {ENV.REACT_APP_NETWORK_SYMBOL}.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerSmall />
                    { !blockchain.account ||
                      !blockchain.smartContract ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Connect to the { ENV.REACT_APP_CHAIN_NAME } network
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            // getData();
                          }}
                        >
                          CONNECT
                        </StyledButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          { blockchain.mintMessage }
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledButton
                            disabled={ blockchain.minting ? 1 : 0 }
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(mint());
                              // getData();
                            }}
                          >
                            { blockchain.minting ? "BUSY" : "BUY" }
                          </StyledButton>
                        </s.Container>
                      </>
                    )}
                  </>
                )}
              </s.TextDescription>
            </s.Container>
            <s.SpacerMedium />
            <s.Container
              flex={5}
              jc={"center"}
              ai={"center"}
              fd={"column"}
              style={{
                backgroundColor: "var(--accent)",
                padding: 24,
                borderRadius: 24,
                border: "4px solid var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              }}
            >
              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    color: "var(--accent-text)"
                  }}
                >
                  Please make sure you are connected to the right network (
                  { ENV.REACT_APP_CHAIN_NAME } Mainnet) and the correct address.
                </s.TextDescription>
              </s.TextTitle>
            </s.Container>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <TitleDescription
          title={"ELDER ENTS NFT_"}
          description={
            `This Video Art Token is the main Crypto Asset
            within ENTS ecosystem. It represents a
            specific Endangered species specimen. If you
            own this token, you become the godparent of that
            single living being, fostering its protection
            through certified local NGOs and access to the
            best earning mechanism of our ecosystem.`
          }
        />
      </s.Container>
    );
}

export default Buy;