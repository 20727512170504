import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from "./components/layout/Header";
import Buy from "./pages/Buy";
import Gallery from "./pages/Gallery";
import { getTokensAlchemy, initializeAlchemy } from "./redux/alchemy/alchemyActions";
import * as s from "./styles/globalStyles";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAlchemy());
    dispatch(getTokensAlchemy());
  }, []);

  return (
    <s.Screen>
      <Router>
        <Header />
        <s.Container
            flex={2}
            fd={"column"}
            jc={"flex-end"}
            ai={"center"}
        >
          <Buy />
          <Gallery />
          {/* <Routes>
            <Route path="/discover" element={<Gallery />} />
            <Route index element={<Buy />} />
          </Routes> */}
        </s.Container>
      </Router>
    </s.Screen>
  );
}

export default App;
