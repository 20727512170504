import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as s from "../styles/globalStyles";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { getTokenOpenseaUrl } from "../utils/utils";
import TokenVideo from "../components/TokenVideo";
import TokenImage from "../components/TokenImage";

const VideoContainer = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  height: 420px;
`;

const VideoIcnContainer = styled.div`
  position: absolute;
  bottom: 12px; 
  right: 12px;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
`;

const TokenImgContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 33.3333%;
  padding: 24px;
  cursor: pointer;
`;

function Gallery() {
  const alchemy = useSelector((state) => state.alchemy);
  const [tokenIdVideoToPlay, setTokenIdVideoToPlay] = useState(null);
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);

  const onVideoLoad = () => {
    setVideoIsLoaded(true);
  }

  const selectVideo = (id) => {
    setVideoIsLoaded(false);
    setTokenIdVideoToPlay(id);
  }

  const goToOpensea = (url) => {
    window.open(url, '_blank');
  }

  const tokens = alchemy.tokens.map(
    (token, i) => {
      const { rawMetadata: metadata, tokenId } = token;
      const {
        animation_url: tokenVideoUrl,
        name: entCode,
        image: tokenImgUrl,
      } = metadata;
      const openseaUrl = getTokenOpenseaUrl(tokenId);

      return(
        <TokenContainer key={tokenId}>
          <TokenImgContainer onClick={() => goToOpensea(openseaUrl) }>
            {
              tokenIdVideoToPlay === i &&
              <VideoContainer hide={!videoIsLoaded}>
                <TokenVideo src={tokenVideoUrl} onLoad={onVideoLoad} />
              </VideoContainer>
            }
            <TokenImage
              entCode={entCode}
              src={tokenImgUrl}
              hide={tokenIdVideoToPlay === i && videoIsLoaded}
            />
            <VideoIcnContainer>
              {
                tokenIdVideoToPlay === i
                ? <StopCircleOutlinedIcon onClick={(e) => { e.stopPropagation(); selectVideo(null); }} />
                : <PlayCircleOutlinedIcon onClick={(e) => { e.stopPropagation(); selectVideo(i); }} />
              }
            </VideoIcnContainer>
          </TokenImgContainer>
          <s.TextSubTitle
            style={{
              width: "100%",
              paddingTop: 5,
              paddingLeft: 10
            }}
          >
            { entCode }
          </s.TextSubTitle>
        </TokenContainer>
      );
    });

  return (
      <>
        <s.Container flex={1} ai={"flex-start"} style={{ padding: "24px 24px 24px 60px" }}>
          <s.MainTitle style={{ color: "var(--primary-text)" }}>
            DISCOVERED ENTS
          </s.MainTitle>
        </s.Container>
        <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            fd={"row"}
            fw={"wrap"}
            style={{ padding: "0 0 24px 0" }}
        > 
          {tokens}
        </s.Container>
      </>
  )
}

export default Gallery;