import { useRef } from "react";
import LazyLoad from "react-lazyload";
import styled, { keyframes } from "styled-components";

const ImageWrapper = styled.div`
    display: ${({ hide }) => (hide ? 'none' : 'block')};
    width: 238px;
    height: 420px;
    border-radius: 12px;
    border: 2px solid var(--secondary);
    background-color: var(--accent);
`;

const loadingAnimation = keyframes`
  0% {
    background-color: var(--accent);
  }
  50% {
    background-color: var(--secondary);
  }
  100% {
    background-color: var(--accent);
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  border-radius: 10px;
  animation: ${loadingAnimation} 1s infinite;
`;

const TokenImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

function TokenImage(props) {
    const { entCode, src, hide } = props;

    const refPlaceholder = useRef();

    const removePlaceholder = () => {
        refPlaceholder.current.remove();
    };

    return(
        <ImageWrapper hide={hide}>
            <Placeholder ref={refPlaceholder} />
            <LazyLoad height={420} offset={200} once={true}>
                <TokenImg
                    alt={entCode}
                    src={src}
                    onLoad={removePlaceholder}
                    onError={removePlaceholder}
                />
            </LazyLoad>
        </ImageWrapper>
    );
}

export default TokenImage;