function TokenVideo(props) {
    const { src, onLoad } = props;
    return (
        <video autoPlay loop onLoadedData={() => onLoad()}
            style={{
                maxWidth: 238,
                height: 420,
                borderRadius: 12,
                border: "2px solid var(--secondary)",
                backgroundColor: "var(--accent)"
            }}
        >
            <source src={src} type="video/mp4" />
        </video>
    )
}

export default TokenVideo;
